.report-header {
    display: grid;
    grid-template-columns: 285px 1fr 285px;
    align-items: center;
    margin-bottom: 16px;
}

.report-header .user-select-block .select {
    z-index: 15;
}

@media (max-width: 720px) {
    .report-header {
        display: flex;
        flex-direction: column;
    }

    .report-header .user-select-block {
        margin-top: 16px;
        width: 285px;
    }
}

.report-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;

    flex-wrap: wrap;
}

.report-head .right-block,
.report-head .left-block {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.report-head .left-block .select {
    z-index: 15;
    min-width: 120px;
}

.report-head .right-block .date-range {
    display: flex;
    gap: 10px;
}

.report-head .right-block .select {
    z-index: 10;
    min-width: 140px;
}

.report-head .right-block .timezone-select {
    min-width: 220px;
}

.report-head .small .css-13cymwt-control,
.report-head .small .css-t3ipsp-control,
.report-filters-modal .css-13cymwt-control,
.report-filters-modal .css-t3ipsp-control,
.report-filters-modal .css-16xfy0z-control {
    min-height: 32px;
    min-height: auto;
}

.report-filters-modal .filter-item input.text-input {
    width: 100%;
    font-size: 11px;
    min-height: 32px;
}

.report-filters-modal input[type="number"] {
    height: 32px;
    padding: 6px;
    font-size: 11px;
}

.report-head .small .css-1xc3v61-indicatorContainer,
.report-head .small .css-15lsz6c-indicatorContainer,
.report-filters-modal .css-15lsz6c-indicatorContainer,
.report-filters-modal .css-1xc3v61-indicatorContainer {
    padding: 5px;
}

.date-range-modal .date-pickers-container {
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.date-range-modal .buttons-block,
.report-filters-modal .buttons-block {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
}

.report-head .custom-date-picker {
    position: relative;
    z-index: 10;
    display: flex;
}

.report-page .right-block .custom-dates {
    display: flex;
    align-items: center;
    gap: 3px;
}

.report-page .right-block .custom-dates .line {
    height: 1px;
    width: 5px;
    min-width: 8px;
    background: var(--primary-text-color);
}

.report-page .right-block .custom-dates .close-btn {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.report-page .right-block .custom-dates .close-btn svg {
    fill: var(--fill-icons);
    transition: all 0.3s ease-in-out;
    width: 18px;
    height: auto;
}

.report-page .right-block .custom-dates .close-btn:hover svg {
    fill: var(--fill-icons-hover);
}

#report-page .report-head .custom-date-picker input {
    font-size: 11px;
    width: 100%;
    height: 32px;
    padding: 6px !important;
    width: 70px;
}

#report-page .report-head .custom-date-picker.with-time input:first-child {
    border-right-width: 0.5px !important;
    border-radius: 6px 0 0 6px !important;
}

#report-page .report-head .custom-date-picker.with-time .time-input {
    border-left-width: 0.5px !important;
    border-radius: 0 6px 6px 0 !important;
    padding: 6px 20px !important;
}

/* settings */
.setting-btn {
    padding: 6px 12px !important;
}
.setting-btn svg {
    width: 16px;
    height: auto;
    display: flex;
}

.setting-btn svg path {
    fill: var(--text-color-warning-btn);
}

.report-settings-modal .select-all-block {
    display: flex;
    align-items: center;
    gap: 3px;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
}

.report-settings-modal .buttons-block {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
}

.sortable-column-list {
    max-height: 500px;
    overflow: auto;
}

.sortable-column-list-wrapper .blackout {
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
    width: 100%;
    height: 30px;
    z-index: 1110;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
}

/*  */
.sortable-column-item {
    display: flex;
    gap: 7px;
    align-items: center;
    position: relative;
    z-index: 1100;
    user-select: none;
    padding: 3px;
}

.sortable-column-item:not(.sortable-column-item.disabled) {
    cursor: grab;
}

.sortable-column-item .drag-handle svg {
    width: 18px;
    height: auto;
}

.sortable-column-item.active {
    cursor: grabbing;
    pointer-events: all !important;
    z-index: 1200;
    box-shadow: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
}

.sortable-column-item-content {
    display: flex;
    align-items: center;
    font-size: 16px;
}

/* filters */

.report-filters-modal .filter-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.report-filters-modal .filter-item {
    display: grid;
    grid-template-columns: 20px 1fr 0.6fr 1fr;
    align-items: center;
    gap: 10px;
}

.report-filters-modal .delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.report-filters-modal .delete-btn.hidden {
    opacity: 0.3;
    pointer-events: none;
}

.report-head .left-block .select-grouping {
    min-width: 180px;
}

.report-head .left-block .select-grouping input {
    min-width: 10px !important;
}

.report-head .right-block .update-btn {
    padding: 6px 14px;
}

.report-head .right-block .update-btn svg {
    width: 16px;
    height: auto;
    display: flex;
}

.report-head .right-block .update-btn svg path,
.report-head .right-block .update-btn svg polyline {
    stroke: var(--text-color-success-btn) !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.report-head .right-block .update-btn.rotate-icon svg {
    animation: rotate 0.7s linear infinite;
}

/* react-table */

.react-table-wrapper {
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
    font-size: 13px;
    position: relative;
}
.react-table-wrapper > div:not(.react-table-wrapper .blackout) {
    position: relative;
    width: calc(100%);
    padding-bottom: 20px;
}

.react-table-wrapper .react-tooltip {
    opacity: 1;
}

.react-table-wrapper .ps__rail-y:hover .ps__thumb-y {
    width: 10px !important;
}

.react-table-wrapper .ps__rail-y, .react-table-wrapper .ps__rail-x {
    cursor: grab;
    z-index: 3;
}

.react-table-wrapper .head-wrapper {
    max-width: 100%;
    position: sticky;
    top: 0;
    z-index: 5;
}

.react-table-wrapper .ps__rail-y:active, .react-table-wrapper .ps__rail-x:active {
    cursor: grabbing;
}

.react-table-wrapper .blackout {
    position: absolute;
    bottom: 20px;
    right: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
    width: 50px;
    height: calc(100% - 50px);
    z-index: 1;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
}

.react-table-wrapper .table {
    transition: opacity 0.1s ease-in-out;
    opacity: 1;
}

.react-table-wrapper .head-tr {
    user-select: none;
}

.react-table-wrapper .table.loading-table {
    opacity: 0.5;
}

.react-table-wrapper .loading-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.react-table-wrapper .loading-bg .loader {
    top: 30%;
}

.react-table-wrapper .table {
    border-spacing: 0;
    width: 100%;
}

.react-table-wrapper .tr:last-child .td {
    border-bottom: 0;
}

.react-table-wrapper .th {
    background-color: var(--th-bg-color-rt);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.react-table-wrapper .th:hover {
    background-color: var(--th-bg-color-hover-rt);
}

.react-table-wrapper .th,
.react-table-wrapper .td {
    margin: 0;
    padding: 8.8px 10px;
    border-bottom: 1px solid var(--border-color-rt);
    border-right: 0;
    position: relative;
}

.react-table-wrapper .td {
    font-family: 'Roboto';
}

.react-table-wrapper .th-body {
    display: flex;
   
}

.react-table-wrapper .th-body * {
    cursor: pointer;
}

.react-table-wrapper .th-body .cell-content,
.react-table-wrapper .td .cell-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-table-wrapper .body-tr {
    cursor: pointer;
}

.react-table-wrapper .body-tr:hover {
    background-color: var(--th-bg-color-rt);
}

.react-table-wrapper .body-tr.active  {
    background-color: var(--tr-bg-color-active)
}

.react-table-wrapper .td:last-child,
.react-table-wrapper .th:last-child {
    border-right: 0;
}

.react-table-wrapper .resizer {
    display: flex;
    justify-content: center;

    height: 100%;
    position: absolute;
    min-width: 14px;
    right: -5px;
    top: 0;
    z-index: 1;
    touch-action: none; /* prevents scrolling while dragging on touch devices */
}

.react-table-wrapper .th:last-child .resizer {
    right: 0;
    min-width: 9px;
    justify-content: end;
}
.react-table-wrapper .resizer > div {
    background: var(--border-color-rt);
    width: 3px;
    pointer-events: none;
    height: 100%;
}

.react-table-wrapper .sort-icon {
    margin-left: 5px;
}

.react-table-wrapper .sort-icon svg {
    width: 15px;
    margin-bottom: -3px;
    height: auto;
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}

.react-table-wrapper .sort-icon.desc svg {
    transform: rotate(270deg);
}

.react-table-wrapper .sort-icon svg path {
    stroke: var(--fill-icons);
}
